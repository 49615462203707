var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Ubah' : 'Tambah') + " " + (_vm.$route.meta.name.singular)),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Merek","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Merek","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Spesifikasi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Spesifikasi","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.specification),callback:function ($$v) {_vm.specification=$$v},expression:"specification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tipe","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Tipe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.typeOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('hr')],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Partner","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","reduce":function (option) { return option.no_partner; },"options":_vm.partnerItems,"state":errors.length > 0 ? false:null,"filterable":false,"placeholder":"Ketik untuk mencari..."},on:{"search":_vm.onSearchPartner},model:{value:(_vm.partnerNum),callback:function ($$v) {_vm.partnerNum=$$v},expression:"partnerNum"}},[(_vm.hasMorePartner)?_c('li',{staticClass:"vs__dropdown-option vs__dropdown-option--disabled",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Pemegang device","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Pemegang device","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.ownerTypeOptions},model:{value:(_vm.ownerType),callback:function ($$v) {_vm.ownerType=$$v},expression:"ownerType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('transition-group',{attrs:{"name":"fade"}},[(_vm.hasTalent)?_c('b-form-group',{key:"form-0",attrs:{"label":"Talent","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Talent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"full_name","reduce":function (option) { return option.id; },"options":_vm.talentItems,"state":errors.length > 0 ? false:null,"filterable":false,"placeholder":"Ketik untuk mencari..."},on:{"search":_vm.onSearchTalent},model:{value:(_vm.talentId),callback:function ($$v) {_vm.talentId=$$v},expression:"talentId"}},[(_vm.hasMoreTalent)?_c('li',{staticClass:"vs__dropdown-option vs__dropdown-option--disabled",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1442302651)})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Diterima","label-cols-md":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ altInput: true, altFormat: 'j F Y', dateFormat: 'Y-m-d',}},model:{value:(_vm.pickDate),callback:function ($$v) {_vm.pickDate=$$v},expression:"pickDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.submitErrors.birth_date))])],1),_c('hr')],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"py-50"},[_vm._v(" Attachment ")])]),_c('b-col',[_c('transition-group',{attrs:{"name":"fade"}},[_vm._l((_vm.documentationFiles),function(item,index){return _c('div',{key:("documentation-file-" + (item.index)),staticClass:"file-group-item"},[_c('b-form-group',[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":("Attachment-" + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.documentationInitialFiles[index] && _vm.documentationInitialFiles[index].attachment_url ?
                                    _vm.documentationInitialFiles[index].attachment_url.split('/').pop()
                                    : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini...","accept":"image/*"},on:{"change":function($event){return _vm.onChangeFile(index)}},model:{value:(_vm.documentationFiles[index].file),callback:function ($$v) {_vm.$set(_vm.documentationFiles[index], "file", $$v)},expression:"documentationFiles[index].file"}}),((_vm.documentationInitialFiles[index]
                                    && _vm.documentationInitialFiles[index].attachment_url)
                                    || _vm.documentationFiles[index].file || index > 0)?_c('b-input-group-append',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Hapus'),expression:"'Hapus'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteDocumentationFile(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),((_vm.documentationInitialFiles[index]
                              && _vm.documentationInitialFiles[index].attachment_url)
                              || _vm.documentationFiles[index].file)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lihat File'),expression:"'Lihat File'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"gradient-secondary","target":"_blank","href":_vm.documentationFiles[index].file ? _vm.fileUrl(_vm.documentationFiles[index].file) : _vm.documentationInitialFiles[index].attachment_url}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}})],1)],1):_vm._e()],1)],1)],1)}),(_vm.documentationFiles.length < 3)?_c('div',{key:"add-more-file",staticClass:"file-group-item"},[_c('b-button',{attrs:{"variant":"outline-dark","size":"sm"},on:{"click":_vm.addMoreFile}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Tambah ")],1)],1):_vm._e()],2)],1)],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }